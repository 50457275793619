/*
  Service actions
*/
// Libraries
import ApolloClient from 'apollo-boost'

// graphql files
import list from 'GraphQL/queries/utils/countries.gql'

// GraphQL initializer
const apollo = new ApolloClient({
  uri: (process.env.NODE_ENV === 'development' ? 'http://localhost:8000/graphql' : 'https://api.solsatel.goldenm.dev/graphql')
})

export default {
  async getCountries ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      fetchPolicy: 'no-cache'
    }).then((response) => {
      console.log('Result', response.data)
      const { status, result } = response.data.countries

      switch (status) {
        case 'OK':
          commit('setCountries', result)
          break

        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  }
}
