<template>
  <v-card height="100%" class="elevation-3">
    <v-card-title class="pb-0">
      <span class="font-weight-light">Lorem ipsum dolor</span>
    </v-card-title>
    <v-card-text class="pb-0">
      <apexchart height="100%" type="bar" :options="chartOptions" :series="series" />
    </v-card-text>
  </v-card>
</template>

<script>
const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  props: {
    isDark: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: '#ff6d46'
    }
  },

  data () {
    return {
      series: []
    }
  },

  computed: {
    chartOptions () {
      return {
        chart: {
          type: 'bar',
          fontFamily: 'Mirai, sans-serif',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.labels
        },
        fill: {
          opacity: 1
        }
      }
    },

    data () {
      return Array(7).fill({}).map(_ => {
        return this.generateNumber()
      })
    },

    labels () {
      return [
        dayjs().subtract(6, 'day').format('DD/MM'),
        dayjs().subtract(5, 'day').format('DD/MM'),
        dayjs().subtract(4, 'day').format('DD/MM'),
        dayjs().subtract(3, 'day').format('DD/MM'),
        dayjs().subtract(2, 'day').format('DD/MM'),
        dayjs().subtract(1, 'day').format('DD/MM'),
        dayjs().format('DD/MM')
      ]
    }
  },

  mounted () {
    this.series = [{
      name: 'Lorem Ipsum',
      data: this.data,
      color: this.color
    }]
  },

  methods: {
    generateNumber (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>
