<template>
  <v-col class="d-flex justify-center align-center">
    <v-row
      no-gutters
      align="center"
      justify="center"
      class="pa-4"
    >
      <v-col cols="12" md="4" class="px-2">
        <v-card class="pb-5">
          <v-card-title class="d-flex justify-center align-center">
            <img height="100" :src="isDark ? $assets.logoWhite : $assets.logo">

            <!-- <div style="display: flex; flex-direction: row; align-items: center; justify-content: center; flex: 1;">
              <img height="60" :src="isDark ? $assets.logoWhite : $assets.logo">
              <div style="display: flex; flex-direction: column; align-items: flex-start;">
                <h3 class="pa-0 ma-0 text-left" style="line-height: 0.9;">
                  Lorem Ipsum
                  <br>
                  <small class="pa-0 ma-0" style="font-size: 14px;">{{ $t('copyright.powered.by') }}</small>
                </h3>
              </div>
            </div> -->
          </v-card-title>

          <v-card-text>
            <v-row no-gutters class="px-5 mt-5">
              <v-col cols="12" class="mb-5">
                <v-text-field
                  v-model="credentials.username"
                  :label="$t('users.username')"
                  outlined
                  rounded
                  hide-details
                  @keydown.enter="handleLogin"
                />
              </v-col>

              <v-col cols="12">
                <v-text-field
                  v-model="credentials.password"
                  :label="$t('users.password')"
                  outlined
                  rounded
                  type="password"
                  hide-details
                  @keydown.enter="handleLogin"
                />
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-actions class="px-6">
            <v-row no-gutters class="px-3">
              <v-col cols="12" class="d-flex">
                <!-- <v-btn
                  v-if="!hasWhitelabel"
                  depressed
                  text
                  color="red darken-2"
                  to="/Recovery"
                >
                  {{ $t('recovery.title') }}
                </v-btn> -->

                <!-- <v-btn depressed color="primary" class="flex" @click="handleLogin">
                  {{ $t('users.signIn') }}
                </v-btn> -->
                <v-btn depressed color="primary" class="flex" @click="handleLogin">
                  {{ $t('users.signIn') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data () {
    return {
      credentials: {
        username: '',
        password: ''
      }
    }
  },

  computed: {
    ...mapState(['width', 'whitelabel', 'isDark']),

    color () {
      if (this.isDark) {
        return 'white black--text'
      }

      return 'primary white--text'
    },

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.whitelabel.logo === undefined) {
        return this.$assets.logo
      }

      return this.whitelabel.logo
    }
  },

  mounted () {
    this.$store.commit('setLoading', false)
  },

  methods: {
    handleLogin () {
      this.$store.dispatch('users/login', this.credentials)
    }
  }
}
</script>