// Libraries
import { apollo } from '@/plugins/apollo'
import router from '@/plugins/router'
import cookies from 'js-cookie'
import { i18n } from '@/plugins'

// graphql files
import login from 'GraphQL/mutations/utils/session.gql'
import auth from 'GraphQL/queries/utils/session.gql'
import list from 'GraphQL/queries/users/list.gql'

export default {
  async getList ({ state, dispatch, commit, rootState }, payload = false) {
    if (payload) {
      commit('setLoading', true, { root: true })
    }

    await apollo.query({
      query: list,
      variables: {
        apiToken: rootState.users.entity.apiToken
      },
      fetchPolicy: 'no-cache'
    }).then((response) => {
      const { status, result } = response.data.users

      switch (status) {
        case 'OK':
          commit('setList', result)
          break

        case 'UNAUTHORIZED':
          commit('toggleSnackbar', { message: 'Acceso denegado', color: 'red darken-2' }, { root: true })
          break
        case 'UNPROCESSABLE':
          commit('toggleSnackbar', undefined, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      if (payload) {
        commit('setLoading', false, { root: true })
      }
    })
  },

  logout ({ state, dispatch, commit, rootState }, payload) {
    commit('setLoading', true, { root: true })

    cookies.remove('api_token')

    router.push('/Login')

    commit('setLoading', false, { root: true })
  },

  async login ({ state, dispatch, commit, rootState }, payload) {
    commit('setLoading', true, { root: true })

    await apollo.mutate({
      mutation: login,
      variables: {
        username: payload.username,
        password: payload.password,
        language: rootState.language
      }
    }).then((response) => {
      const { status, errors, result } = response.data.login
      console.log(errors)
      switch (status) {
        case 'OK':
          console.log(result.apiToken)
          cookies.set('api_token', result.apiToken)
          commit('setEntity', {
            ...result,
            loaded: true
          })
          dispatch('loadData')
          break
        case 'BADREQUEST':
          commit('toggleSnackbar', { message: i18n.t('errors.invalidFields'), color: 'warning' }, { root: true })
          break
        case 'NOTFOUND':
          commit('toggleSnackbar', { message: i18n.t('errors.user.notFound'), color: 'warning' }, { root: true })
          break
        case 'INTERNALERROR':
          commit('toggleSnackbar', { message: i18n.t('helpers.errors.disaster'), color: 'red darken-2' }, { root: true })
          break
      }
    }).catch((error) => {
      commit('toggleSnackbar', undefined, { root: true })
    }).finally(() => {
      commit('setLoading', false, { root: true })
    })
  },

  async loadEntity ({ state, dispatch, commit, rootState }, payload) {
    const apiToken = cookies.get('api_token')

    if (apiToken !== undefined) {
      await apollo.query({
        query: auth,
        variables: {
          apiToken,
          language: rootState.language
        },
        fetchPolicy: 'no-cache'
      }).then((response) => {
        const { status, result } = response.data.getSession
        switch (status) {
          case 'OK':
            cookies.set('api_token', result.apiToken)

            commit('setEntity', {
              ...result,
              loaded: true
            })

            if (payload === undefined) {
              dispatch('loadData', payload)
            }
            break
          default:
            router.push('/Login')
            break
        }
      }).catch((error) => {
        commit('toggleSnackbar', undefined, { root: true })
      }).finally(() => {
        commit('setLoading', false, { root: true })
      })
    }
  },

  async loadData ({ state, dispatch, commit, rootState }, payload) {
    const lastUrl = window.location.href.split('#')[1]

    await dispatch('requisitions/getList', false, { root: true })
    await dispatch('workshops/getList', false, { root: true })
    await dispatch('crashes/getList', false, { root: true })
    await dispatch('users/getList', false, { root: true })

    // if (state.entity.permission.users >= 1) {
    //   await dispatch('users/getList', false, { root: true })
    // }

    await commit('setLoading', false, { root: true })

    if (payload === undefined) {
      let redirect = lastUrl

      if (lastUrl === '/Login') {
        if (rootState.users.entity.kind === 'ADMIN') {
          redirect = '/Home'
        } else if (rootState.users.entity.kind === 'SUPERVISOR') {
          redirect = '/Reports'
        } else {
          redirect = '/Operator/Home'
        }
      }

      router.push(redirect)
    } else {
      if (rootState.users.entity.kind === 'ADMIN') {
        router.push('/Home')
      } else if (rootState.users.entity.kind === 'SUPERVISOR') {
        router.push('/Reports')
      } else {
        router.push('/Operator/Home')
      }
    }
  }
}
