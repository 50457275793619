<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" sm="6" md="3" class="pa-2">
        <v-datetime-picker
          v-model="startAt"
          outlined
          rounded
          :label="$t('reports.startAt')"
          :error="hasErrors('startAt')"
          :error-messages="getErrors('startAt')"
          :textFieldProps="{'hide-details': true, 'rounded': true, 'prepend-inner-icon': 'mdi-calendar', height: '50px', dense: true, class: 'datetime-input'}"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pa-2">
        <v-datetime-picker
          v-model="endAt"
          outlined
          rounded
          :label="$t('reports.endAt')"
          :error="hasErrors('endAt')"
          :error-messages="getErrors('endAt')"
          :textFieldProps="{'hide-details': true, 'rounded': true, 'prepend-inner-icon': 'mdi-calendar', height: '50px', dense: true, class: 'datetime-input'}"
        />
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pa-2 d-flex">
        <v-btn :ripple="false" depressed class="flex text-capitalize" color="primary" @click="generateReport('consult')">
          <v-icon class="mr-3">mdi-database</v-icon>
          {{ $t('actions.checkReport') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="6" md="3" class="pa-2 d-flex">
        <v-btn :ripple="false" outlined depressed class="flex text-capitalize" color="primary"
               @click="generateReport('export')"
        >
          <v-icon class="mr-3">mdi-file-export</v-icon>
          {{ $t('actions.exportReport') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-card height="100%" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="consultData"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.geofence`]="{ item }">
              <p v-if="item.geofence" class="text-center ma-0">{{ item.geofence }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.startCreationDate`]="{ item }">
              <p v-if="item.startDateCreation" class="text-center ma-0">{{ parseDate(item.startDateCreation) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.startCreationHour`]="{ item }">
              <p v-if="item.startDateCreation" class="text-center ma-0">{{ parseHour(item.startDateCreation) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.createdAt`]="{ item }">
              <p v-if="item.createdAt" class="text-center ma-0">{{ parseHour(item.createdAt) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.totalCreation`]="{ item }">
              <p v-if="item.totalCreation" class="text-center ma-0">{{ parseDuration(item.totalCreation) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.assignmentDate`]="{ item }">
              <p v-if="item.assignmentDate" class="text-center ma-0">{{ parseHour(item.assignmentDate) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.estimatedArrivalTime`]="{ item }">
              <p v-if="item.estimatedArrivalTime" class="text-center ma-0">{{ parseDuration(item.estimatedArrivalTime) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.arrivalDate`]="{ item }">
              <p v-if="item.arrivalDate" class="text-center ma-0">{{ parseHour(item.arrivalDate) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.totalArrival`]="{ item }">
              <p v-if="item.totalArrival" class="text-center ma-0">{{ parseDuration(item.totalArrival) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.confirmationDate`]="{ item }">
              <p v-if="item.confirmationDate" class="text-center ma-0">{{ parseHour(item.confirmationDate) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.totalComplete`]="{ item }">
              <p v-if="item.totalComplete" class="text-center ma-0">{{ parseDuration(item.totalComplete) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.totalCase`]="{ item }">
              <p v-if="item.totalCase" class="text-center ma-0">{{ parseDuration(item.totalCase) }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.crash.name`]="{ item }">
              <p v-if="item.crash" class="text-center ma-0">{{ item.crash.name }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <p class="text-center ma-0">{{ $t(`reports.requisition.statuses.${item.status}`) }}</p>
            </template>
          </v-data-table>
          <v-spacer />
          <div class="text-center ml-auto pt-2">
            <v-pagination
              v-model="page"
              circle
              :length="pageCount"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import consult from 'GraphQL/mutations/reports/consult.gql'
import exportReport from 'GraphQL/mutations/reports/export.gql'
import { orm } from '@/mixins'
const dayjs = require('dayjs')
require('dayjs/locale/es')
var duration = require('dayjs/plugin/duration')
dayjs.locale('es')
dayjs.extend(duration)

export default {
  mixins: [orm],
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      consultData: [],
      errors: {},
      startAt: undefined,
      endAt: undefined
    }
  },

  computed: {
    ...mapState(['isDark', 'users']),

    headers () {
      return [
        {
          text: this.$i18n.t('reports.requisition.date'),
          align: 'start',
          sortable: false,
          value: 'startCreationDate'
        },
        { text: this.$i18n.t('reports.requisition.caseNumber'), value: 'caseNumber', sortable: false },
        { text: this.$i18n.t('reports.requisition.customer'), value: 'customer', sortable: false },
        { text: this.$i18n.t('reports.requisition.geofence'), value: 'geofence', sortable: false },
        { text: this.$i18n.t('reports.requisition.address'), value: 'address', sortable: false },
        { text: this.$i18n.t('reports.requisition.driver'), value: 'driver', sortable: false },
        { text: this.$i18n.t('reports.requisition.startDateCreation'), value: 'startCreationHour', sortable: false },
        { text: this.$i18n.t('reports.requisition.createdAt'), value: 'createdAt', sortable: false },
        { text: this.$i18n.t('reports.requisition.totalCreation'), value: 'totalCreation', sortable: false },
        { text: this.$i18n.t('reports.requisition.assignmentDate'), value: 'assignmentDate', sortable: false },
        { text: this.$i18n.t('reports.requisition.estimatedArrivalTime'), value: 'estimatedArrivalTime', sortable: false },
        { text: this.$i18n.t('reports.requisition.arrivalDate'), value: 'arrivalDate', sortable: false },
        { text: this.$i18n.t('reports.requisition.totalArrival'), value: 'totalArrival', sortable: false },
        { text: this.$i18n.t('reports.requisition.confirmationDate'), value: 'confirmationDate', sortable: false },
        { text: this.$i18n.t('reports.requisition.totalComplete'), value: 'totalComplete', sortable: false },
        { text: this.$i18n.t('reports.requisition.totalCase'), value: 'totalCase', sortable: false },

        { text: this.$i18n.t('reports.requisition.plate'), value: 'plate', sortable: false },
        { text: this.$i18n.t('reports.requisition.brand'), value: 'brand', sortable: false },
        { text: this.$i18n.t('reports.requisition.model'), value: 'model', sortable: false },
        { text: this.$i18n.t('reports.requisition.color'), value: 'color', sortable: false },
        { text: this.$i18n.t('reports.requisition.year'), value: 'year', sortable: false },
        { text: this.$i18n.t('reports.requisition.mainPhoneNumber'), value: 'mainPhoneNumber', sortable: false },
        { text: this.$i18n.t('reports.requisition.secondPhoneNumber'), value: 'secondPhoneNumber', sortable: false },
        { text: this.$i18n.t('reports.requisition.email'), value: 'email', sortable: false },
        { text: this.$i18n.t('reports.requisition.contactName'), value: 'contactName', sortable: false },
        { text: this.$i18n.t('reports.requisition.workshop'), value: 'workshopName', sortable: false },
        { text: this.$i18n.t('reports.requisition.crash'), value: 'crashName', sortable: false },
        { text: this.$i18n.t('reports.requisition.comments'), value: 'comments', sortable: false },
        { text: this.$i18n.t('reports.requisition.guideMark'), value: 'guideMark', sortable: false },
        { text: this.$i18n.t('reports.requisition.status'), value: 'status', sortable: false }
      ]
    }
  },

  methods: {
    parseDate (unix) {
      console.log('unix', unix)
      return dayjs(unix * 1000).format('DD-MM-YYYY')
    },
    parseHour (unix) {
      return dayjs(unix * 1000).format('HH:mm:ss')
    },
    parseDuration (unix) {
      const hours = parseInt(unix / 3600)
      const minutes = parseInt((unix % 3600) / 60)
      const seconds = parseInt(unix % 60)
      return dayjs.duration({ hours: hours, minutes: minutes, seconds: seconds }).format('HH:mm:ss')
    },
    generateReport (type) {
      let mutation = consult
      let mutationName = 'generateRequisitionConsult'
      const data = {
        startAt: dayjs(this.startAt).unix(),
        endAt: dayjs(this.endAt).unix()
      }
      console.log(data)
      if (type === 'export') {
        mutation = exportReport
        mutationName = 'exportRequisitionReport'
      }
      this.$apollo.mutate({
        mutation: mutation,
        variables: {
          apiToken: this.users.entity.apiToken,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        console.log(response.data)
        const { status, errors, result } = response.data[mutationName]
        if (status === 'OK') {
          if (type === 'consult') {
            this.consultData = result
            console.log(this.consultData)
          } else {
            window.open(process.env.NODE_ENV === 'development' ? `http://localhost:8000/public/${result}` : `https://api.solsatel.goldenm.dev/static/${result}`, '_blank')
            this.$store.commit('toggleSnackbar', {
              message: this.$i18n.t('reports.success.generated'),
              color: 'success'
            })
          }
        } else {
          this.errors = this.parseErrors(errors)
          if (status === 'BADREQUEST') {
            this.$store.commit('toggleSnackbar', {
              color: 'orange darken-2',
              message: this.$t('errors.invalidFields')
            })
          } else {
            this.$store.commit('toggleSnackbar')
          }
        }
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    }
  }
}
</script>