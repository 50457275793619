import LoginLayout from '@/layouts/Login.vue'
import AppLayout from '@/layouts/Main.vue'

import NotFound from '@/views/NotFound.vue'
import Login from '@/views/Login.vue'
import Recovery from '@/views/Recovery.vue'
import Home from '@/views/Home.vue'
import Reports from '@/views/Reports.vue'
import OperatorHome from '@/views/OperatorHome.vue'
import Users from '@/views/Users.vue'
import Workshops from '@/views/Workshops.vue'
import Crashes from '@/views/Crashes.vue'

export default [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/',
    component: LoginLayout,
    children: [
      {
        path: 'Login',
        name: 'login',
        components: { default: Login }
      },
      {
        path: 'Recovery',
        name: 'recovery',
        components: { default: Recovery }
      }
    ]
  },
  {
    path: '/',
    component: AppLayout,
    children: [
      {
        path: 'Home',
        name: 'home',
        components: { default: Home }
      },
      {
        path: 'Users',
        name: 'users',
        components: { default: Users }
      },
      {
        path: 'Workshops',
        name: 'workshops',
        components: { default: Workshops }
      },
      {
        path: 'Crashes',
        name: 'crashes',
        components: { default: Crashes }
      },
      {
        path: 'Reports',
        name: 'reports',
        components: { default: Reports }
      },
      {
        path: 'Operator/Home',
        name: 'operator-home',
        components: { default: OperatorHome }
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]
