export default {
  methods: {
    formsFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.caseNumber.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.customer.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.email.toLowerCase().includes(search.toLowerCase())
      const scan3 = item.mainPhoneNumber.toLowerCase().includes(search.toLowerCase())
      const scan4 = item.createdAt.toLowerCase().includes(search.toLowerCase())
      const scan5 = item.assignmentDate ? item.assignmentDate.toLowerCase().includes(search.toLowerCase()) : false
      const scan6 = item.createdByName.toLowerCase().includes(search.toLowerCase())
      const scan7 = item.status.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2 || scan3 || scan4 || scan5 || scan6 || scan7
    },
    usersFilter (_, search, item) {
      if (search.length === 0) {
        return true
      }

      const scan0 = item.name.toLowerCase().includes(search.toLowerCase())
      const scan1 = item.username.toLowerCase().includes(search.toLowerCase())
      const scan2 = item.email.toLowerCase().includes(search.toLowerCase())
      return scan0 || scan1 || scan2
    }
  }
}
