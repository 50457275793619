export default {
  entity: {
    id: null,
    name: 'Kenny',
    lastName: 'Mochizuki',
    apiToken: '',
    loaded: false,
    email: ''
  },

  list: []
}