<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="5" class="d-flex flex-column">
        <v-row no-gutters>
          <v-col cols="6" class="d-flex flex-column pa-2">
            <information-card
              :is-dark="isDark"
              :value="generateNumber()"
              :title="$t('forms.title')"
              :subtitle="$t('forms.madeToday')"
            />
          </v-col>
          <v-col cols="6" class="d-flex flex-column pa-2">
            <information-card
              :is-dark="isDark"
              :value="generateNumber()"
              :title="$t('forms.title')"
              :subtitle="$t('forms.attendedToday')"
            />
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="6" class="pa-2 d-flex flex-column">
            <timer-card
              :is-dark="isDark"
              badge-color="seaWater"
              time="00:00 min"
            />
          </v-col>
          <v-col cols="6" class="pa-2 d-flex flex-column">
            <timer-card
              :is-dark="isDark"
              badge-color="lavender"
              time="00:00 hrs"
            />
          </v-col>
        </v-row>

        <v-spacer />
        <v-row no-gutters>
          <v-col v-for="(el, i) in timeOptions" :key="i" cols="4" class="px-2 py-1 d-flex flex-column">
            <v-btn
              small
              :ripple="false"
              :outlined="timeSelected !== el.id"
              class="text-lowercase"
              color="primary"
              depressed
              @click="timeSelected = el.id"
            >
              {{ el.text }}
            </v-btn>
          </v-col>
        </v-row>

        <v-spacer />
        <v-row no-gutters>
          <v-col cols="12" class="pa-2">
            <home-chart color="#00cec5" />
          </v-col>
          <v-col cols="12" class="pa-2">
            <home-chart color="#7878be" />
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" md="7" class="pa-2 d-flex flex-column">
        <v-card height="100%" min-height="calc(100vh - 112px)" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:item.actions>
              <v-btn small icon>
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-spacer />
          <div class="text-center ml-auto pt-2">
            <v-pagination
              v-model="page"
              circle
              :length="pageCount"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { InformationCard, TimerCard } from 'Components/utils'
import { Chart as HomeChart } from 'Components/home'

const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  components: {
    InformationCard,
    TimerCard,
    HomeChart
  },
  data () {
    return {
      timeSelected: 0,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10
    }
  },

  computed: {
    ...mapState(['isDark']),

    users () {
      return 0
    },

    customers () {
      return 0
    },

    whitelabels () {
      return 0
    },

    timeOptions () {
      return [
        { id: 0, text: this.$i18n.t('home.time.last7days') },
        { id: 1, text: this.$i18n.t('home.time.last15days') },
        { id: 2, text: this.$i18n.t('home.time.lastmonth') },
        { id: 3, text: this.$i18n.t('home.time.last3months') },
        { id: 4, text: this.$i18n.t('home.time.last6months') },
        { id: 5, text: this.$i18n.t('home.time.last12months') }
      ]
    },

    headers () {
      return [
        {
          text: this.$i18n.t('forms.caseNumber'),
          align: 'start',
          sortable: false,
          value: 'caseNumber'
        },
        { text: this.$i18n.t('forms.customer'), value: 'customer' },
        { text: this.$i18n.t('forms.email'), value: 'email', sortable: false },
        { text: this.$i18n.t('forms.phone'), value: 'phone', sortable: false },
        { text: this.$i18n.t('forms.date'), value: 'date', sortable: false },
        { text: this.$i18n.t('forms.operator'), value: 'operator', sortable: false },
        { text: '', value: 'actions', sortable: false }
      ]
    },

    data () {
      return Array(20).fill({}).map((_, i) => {
        return {
          id: i,
          caseNumber: this.generateNumber(100000, 999999),
          customer: `Cliente ${i + 1}`,
          email: `cliente${i + 1}@email.com`,
          phone: `+58-${this.generateNumber(1000000, 9999999)}`,
          date: dayjs().format('DD/MM/YYYY hh:mm a'),
          operator: `Operador ${i + 1}`
        }
      })
    }
  },

  methods: {
    generateNumber (min = 0, max = 100) {
      return Math.floor(Math.random() * (max - min + 1)) + min
    }
  }
}
</script>