<template>
  <div :id="mapId" :style="styles" />
</template>

<script>
import { mapGetters } from 'vuex'
import axios from 'axios'

export default {
  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    show: { type: Boolean, default: () => false },

    data: {
      type: Object,
      default: () => {}
    },

    mapId: {
      type: String,
      default: () => 'map'
    },

    dark: {
      type: Boolean,
      default: () => false
    },

    width: {
      type: String,
      default: () => '100%'
    },

    height: {
      type: String,
      default: () => '100%'
    }
  },

  data () {
    return {
      map: undefined,
      google: null,
      geocoder: null,
      addressMarker: null
    }
  },

  computed: {
    ...mapGetters(['color']),

    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('set-object', value)
      }
    },

    styles () {
      return `
        width: ${this.width};
        height: ${this.height};
        z-index: 1;
      `
    }
  },

  watch: {
    show (value) {
      if (value) {
        if (this.object.location.length !== 0 && this.object.coordinates.lat && this.object.coordinates.lng) {
          if (this.addressMarker) {
            this.removeMarker()
          }
          this.placeMarker(this.object.coordinates)
        }
      } else {
        if (this.addressMarker) {
          this.removeMarker()
        }
        this.object.location = ''
        this.object.coordinates = Object.assign({}, { lat: null, lng: null })
      }
    }
  },

  beforeCreate () {
    /* eslint-disable no-undef, no-unused-vars */
    this.$googlemaps.load().then(() => {
      this.google = google
      this.initMap()
    })
    /* eslint-enable */
  },

  created () {
    this.$googlemaps.load().then(() => {
      this.google.maps.event.addDomListener(window, 'load', this.initAutocomplete())
    })
  },

  mounted () {},

  beforeDestroy () {},

  methods: {
    async initMap () {
      const self = this
      let initialLocation = { lat: 0, lng: 0 }

      this.geocoder = new this.google.maps.Geocoder()

      this.map = new this.google.maps.Map(
        document.getElementById(this.mapId),
        {
          zoom: 16,
          center: initialLocation
        }
      )

      this.google.maps.event.addListener(this.map, 'click', function (event) {
        if (self.addressMarker) {
          self.removeMarker()
        }
        self.placeMarker(event.latLng)
        self.calculateAddress(event)
      })

      setTimeout(() => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function (position) {
            initialLocation = new self.google.maps.LatLng(position.coords.latitude, position.coords.longitude)
            self.map.setCenter(initialLocation)
          })
        }
      }, 0)
    },

    initAutocomplete () {
      const self = this
      var input = document.getElementById('locationTextField')
      var autocomplete = new this.google.maps.places.Autocomplete(input)
      input.placeholder = ''

      this.google.maps.event.addListener(autocomplete, 'place_changed', function () {
        var place = autocomplete.getPlace()
        document.getElementById('city2').value = place.formatted_address
        document.getElementById('cityLat').value = place.geometry.location.lat()
        document.getElementById('cityLng').value = place.geometry.location.lng()
        if (self.addressMarker) {
          self.removeMarker()
        }
        self.object.location = place.formatted_address
        self.object.coordinates = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() }
        self.placeMarker({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() })
      })
    },

    calculateAddress (event) {
      const gisUrl = 'https://nominatim.openstreetmap.org'
      const self = this
      const url = `${gisUrl}/reverse?format=jsonv2&lat=${event.latLng.lat()}&lon=${event.latLng.lng()}`
      axios.get(url)
        .then(function (response) {
          // handle success
          document.getElementById('city2').value = response.data.display_name
          document.getElementById('cityLat').value = event.latLng.lat()
          document.getElementById('cityLng').value = event.latLng.lng()
          self.object.location = response.data.display_name
          self.object.coordinates = { lat: event.latLng.lat(), lng: event.latLng.lng() }
        })
        .catch(function (error) {
          // handle error
          console.log(error)
          self.object.location = this.$i18n.t('errors.maps.address')
          self.object.coordinates = { lat: null, lng: null }
        })
        .then(function () {
          // always executed
        })
    },

    // codeAddress () {
    //   var address = ''
    //   this.geocoder.geocode({ address: address }, function (results, status) {
    //     if (status === 'OK') {
    //       this.map.setCenter(results[0].geometry.location)
    //       this.addressMarkermarker = new this.google.maps.Marker({
    //         map: this.map,
    //         position: results[0].geometry.location
    //       })
    //       console.log('address :>> ', address)
    //     } else {
    //       alert('Geocode was not successful for the following reason: ' + status)
    //     }
    //   })
    // },

    removeMarker () {
      this.addressMarker.setMap(null)
      this.addressMarker = null
    },

    placeMarker (location) {
      this.addressMarker = new this.google.maps.Marker({
        position: location,
        map: this.map
      })
      setTimeout(() => {
        this.map.setCenter(this.addressMarker.getPosition())
      }, 100)
    }
  }
}
</script>
