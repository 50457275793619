<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="12" md="6" offset-md="6" class="pa-2 d-flex align-center justify-end">
        <v-btn icon class="mr-3">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          small
          :ripple="false"
          depressed
          class="text-capitalize"
          color="primary"
          @click="openDialog"
        >
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          {{ $t('actions.createCrash') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-card height="100%" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :page.sync="page"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn small icon>
                <v-icon @click="alterAction('show', item.id)" small>mdi-eye</v-icon>
              </v-btn>
              <v-btn small icon>
                <v-icon @click="alterAction('edit', item.id)" small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn small icon>
                <v-icon @click="openConfirmDialog(item.id)" small>mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
          <v-spacer />
          <div class="text-center ml-auto pt-2">
            <v-pagination
              v-model="page"
              circle
              :length="pageCount"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <crash-dialog
      v-model="object"
      :show-dialog="dialog"
      :isEditting="isEditting"
      @toggle-dialog="state => dialog = state"
    />

    <v-dialog
      v-model="confirmDialog"
      persistent
      width="40vw"
    >
      <v-card>
        <v-card-title>
          {{ $t('helpers.delete.confirm') }}
        </v-card-title>

        <v-card-actions>
          <v-btn
            small
            :ripple="false"
            outlined
            class="text-capitalize"
            dark
            color="primary"
            @click="closeConfirmDialog()"
          >
            {{ $t('actions.back') }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            :ripple="false"
            class="text-capitalize"
            dark
            color="primary"
            @click="alterAction('delete', deleteId)"
          >
            {{ $t('actions.continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { Dialog as CrashDialog } from 'Components/crashes'
import list from 'GraphQL/queries/crashes/list.gql'
import deleteMutation from 'GraphQL/mutations/crashes/delete.gql'

const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  components: {
    CrashDialog
  },
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      isEditting: false,
      object: {},
      dialog: false,
      confirmDialog: false,
      deleteId: null
    }
  },
  computed: {
    ...mapState(['isDark', 'users', 'crashes']),

    headers () {
      return [
        {
          text: this.$i18n.t('crashes.name'),
          align: 'center',
          sortable: true,
          value: 'name'
        },
        { text: '', value: 'actions', sortable: false }
      ]
    },

    data () {
      return this.crashes.list
    }
  },
  created () {
    this.object = Object.assign({}, this.$store.getters['crashes/defaultObject'])
  },
  methods: {
    openDialog () {
      this.dialog = true
      this.isEditting = true
      this.object = Object.assign({}, this.$store.getters['crashes/defaultObject'])
    },
    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      console.log(action)
      switch (status) {
        case 'OK':
          if (action !== 'delete') {
            this.object = result[0]
            this.dialog = true
          } else {
            this.closeConfirmDialog()
            this.$store.dispatch('crashes/getList', true)
          }
          break
        case 'UNPROCESSABLE':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'ACCESSDENIED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.accessdenied')
          })
          break
      }
    },
    getDetail (id, action) {
      this.$apollo.query({
        query: list,
        variables: {
          apiToken: this.users.entity.apiToken,
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.crashes
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },
    
    deleteItem (id, action) {
      this.$apollo.mutate({
        mutation: deleteMutation,
        variables: {
          apiToken: this.users.entity.apiToken,
          ids: [id]
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.deleteCrashes
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    alterAction (action, id) {
      if (action === 'show') {
        this.isEditting = false
        this.getDetail(id, action)
      } else if (action === 'edit') {
        this.isEditting = true
        this.getDetail(id, action)
      } else {
        this.deleteItem(id, action)
      }
    },

    openConfirmDialog (id) {
      this.confirmDialog = true
      this.deleteId = id
    },

    closeConfirmDialog () {
      this.confirmDialog = false
      this.deleteId = null
    }
  }
}
</script>