export default {
  defaultObject () {
    return {
      id: undefined,
      caseNumber: '',
      customer: '',
      workshopId: '',
      crashId: '',
      mainPhoneNumber: '',
      secondPhoneNumber: '',
      address: '',
      coordinates: { latitude: null, longitude: null },
      startDateCreation: null,
      driver: '',
      locator: '',
      confirmationDate: null,
      refusedDate: null,
      estimatedArrivalTime: '',
      guideMark: '',
      plate: '',
      brand: '',
      model: '',
      color: '',
      year: undefined,
      contactName: '',
      comments: ''
    }
  }
}