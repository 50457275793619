import Vue from 'vue'
import App from './App.vue'
import { i18n, vuetify, assets, router, store, cookies, apollo, googlemaps } from '@/plugins'
import DatetimePicker from '@goldenm/vuetify-datetime-picker'
import VueApexCharts from 'vue-apexcharts'
import VueTelInputVuetify from 'vue-tel-input-vuetify'

// import axios from 'axios'

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import '@/assets/application.scss'

Vue.config.productionTip = false

Vue.use(assets)
Vue.use(cookies)
Vue.use(DatetimePicker)
Vue.use(VueApexCharts)
Vue.use(googlemaps)
Vue.use(VueTelInputVuetify, {
  vuetify
})

Vue.component('apexchart', VueApexCharts)

new Vue({
  i18n,
  vuetify,
  router,
  store,
  apolloProvider: apollo,

  computed: {
    messages () {
      return this.$i18n.messages
    },

    title () {
      if (this.whitelabel.name === undefined) {
        return this.$i18n.t('app.site')
      }

      return this.whitelabel.name
    },

    whitelabel () {
      return this.$store.state.whitelabel
    }
  },

  watch: {
    $route (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${newVal.name}`)} | ${this.title}`
    },

    messages (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
    },

    whitelabel (newVal, oldVal) {
      document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
    }
  },

  beforeCreate () {
    window.addEventListener('resize', () => {
      this.$store.commit('setWidth', window.innerWidth)

      if (window.innerWidth <= 930) {
        this.$store.commit('setMini', false)
      }
    })

    document.title = this.title

    const cookies = this.$cookies.get('state')
    if (cookies !== undefined) {
      this.$store.commit('setCookies', cookies === 'false')
    }

    const dark = this.$cookies.get('dark')
    if (dark !== undefined) {
      this.$store.commit('setDark', dark === 'true')
      this.$vuetify.theme.dark = dark === 'true'
    }
  },

  created () {
    const apiToken = this.$cookies.get('api_token')
    console.log(apiToken)
    if (apiToken !== undefined) {
      this.$store.dispatch('users/loadEntity')
    } else {
      this.$router.push('/Login')
      this.$store.commit('setLoading', false)
    }

    // const url = process.env.NODE_ENV === 'development' ? 'http://localhost:8000/api/keys/fetch' : `https://${window.location.hostname}/api/keys/fetch`
    // axios.get(url).then((response) => {
    //   this.$store.commit('languages/setDefaultKeys', response.data)
    // })
  },

  mounted () {
    document.title = `${this.$i18n.t(`pages.${this.$route.name}`)} | ${this.title}`
  },

  beforeDestroy () {
    window.removeEventListener('resize')
  },

  render: h => h(App)
}).$mount('#app')
