export default {
  setRequisitions (state, payload) {
    state.list = payload
  },

  updateItem (state, payload) {
    const list = [...state.list]
    for (let i = 0; i < list.length; i++) {
      if (list[i].id.toString() === payload.id.toString()) {
        list[i] = Object.assign({}, payload)
      }
    }

    state.list = []
    state.list = [...list]
  }
}