// Main imports
import Vue from 'vue'
import Vuetify from 'vuetify'

// // Vuetify locales
import en from 'vuetify/es5/locale/en'

const colors = {
  primary: '#ff6d46',
  secondary: '#7a7a87',
  seaWater: '#00cec5',
  lavender: '#7878be',
  greenForest: '#006f44',
  greenLime: '#9ea700',
  mango: '#ef9c21'
}

// Create component
Vue.use(Vuetify)

export default new Vuetify({
  lang: {
    locales: { en },
    current: 'en'
  },

  icons: {
    iconfont: 'mdi',
    values: {
      cancel: 'mdi-close',
      prev: 'mdi-chevron-left',
      next: 'mdi-chevron-right'
    }
  },

  theme: {
    dark: false,
    themes: {
      dark: colors,
      light: colors
    },

    options: {
      customProperties: true
    }
  }
})
