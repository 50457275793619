export default {
  avatar (state) {
    if (state.entity.avatar) {
      return state.entity.avatar
    }
    return `${state.entity.name[0]}`
  },

  hasAvatar (state) {
    return !(state.entity.avatar === undefined || state.entity.avatar === null)
  },

  fullName (state) {
    if (state.entity.name) {
      return `${state.entity.name}`
    }
    return ''
  },

  defaultObject () {
    return {
      id: null,
      name: '',
      email: '',
      username: '',
      password: '',
      kind: 'ADMIN'
    }
  }
}