<template>
  <v-container fill-height fluid pa-0>
    <v-layout align-center justify-center class="primary">
      <transition name="component-fade" mode="out-in">
        <router-view />
      </transition>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'

export default {
  components: {
  },

  data () {
    return {
      fab: false
    }
  },

  computed: {
    ...mapState(['whitelabel', 'isDark']),

    background () {
      if (this.whitelabel.background === undefined) {
        return this.$assets.background
      }

      return this.whitelabel.background
    },

    styles () {
      return `background-image: url(${this.background}); background-size: cover; background-position: center;`
    }
  }
}
</script>

<style>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter, .component-fade-leave-to
/* .component-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>