import users from './users'
import languages from './languages'
import requisitions from './requisitions'
import workshops from './workshops'
import crashes from './crashes'

export default {
  users,
  languages,
  requisitions,
  workshops,
  crashes
}