// Main imports
import Vue from 'vue'
import VueApollo from 'vue-apollo'

// Apollo client dependencies
import { ApolloClient } from 'apollo-client'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'

// Create component
Vue.use(VueApollo)

const httpLink = new HttpLink({
  uri: (process.env.NODE_ENV === 'development' ? 'http://localhost:8000/graphql' : 'https://api.solsatel.goldenm.dev/graphql')
})

const apolloClient = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache()
})

export default new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache'
    }
  }
})

export { apolloClient as apollo }
