<template>
  <v-container fluid>
    <v-row no-gutters>
      <v-col cols="6" class="d-flex flex-column pa-2">
        <information-card
          :is-dark="isDark"
          reverse
          icon="mdi-text-box-outline"
          :value="todayRequisitonsCount()"
          :title="$t('forms.title')"
          :subtitle="$t('forms.madeToday')"
        />
      </v-col>
      <v-col cols="6" class="d-flex flex-column pa-2">
        <information-card
          :is-dark="isDark"
          reverse
          icon="mdi-text-box-outline"
          color="primary"
          text-color="white"
          :value="yesterdayRequisitonsCount()"
          :title="$t('forms.title')"
          :subtitle="$t('forms.madeYesterday')"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" sm="5" class="pa-2">
        <v-datetime-picker
          v-model="startAt"
          outlined
          rounded
          :label="$t('reports.startAt')"
          :error="hasErrors('startAt')"
          :error-messages="getErrors('startAt')"
          :textFieldProps="{'hide-details': true, 'rounded': true, 'prepend-inner-icon': 'mdi-calendar', height: '45px', dense: true, class: 'datetime-input datetime--dense'}"
        />
      </v-col>
      <v-col cols="12" sm="5" class="pa-2">
        <v-datetime-picker
          v-model="endAt"
          outlined
          rounded
          :label="$t('reports.endAt')"
          :error="hasErrors('endAt')"
          :error-messages="getErrors('endAt')"
          :textFieldProps="{'hide-details': true, 'rounded': true, 'prepend-inner-icon': 'mdi-calendar', height: '45px', dense: true, class: 'datetime-input datetime--dense'}"
        />
      </v-col>
      <v-col cols="12" :sm="isConsulted ? '1' : '2'" class="pa-2 d-flex justify-end align-center">
        <v-btn
          small
          :ripple="false"
          outlined
          class="text-capitalize"
          width="100%"
          color="primary"
          :disabled="!validateSearch"
          @click="searchRequisitions('historic')"
        >
          <v-icon>mdi-clipboard-search-outline</v-icon>
          {{ $t('actions.search') }}
        </v-btn>
      </v-col>
      <v-col v-if="isConsulted" cols="12" sm="1" class="pa-2 d-flex justify-end align-center">
        <v-btn
          small
          :ripple="false"
          outlined
          class="text-capitalize"
          width="100%"
          color="secondary"
          @click="searchRequisitions('initial')"
        >
          <v-icon>mdi-backup-restore</v-icon>
          {{ $t('actions.goBack') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex align-center justify-end">
        <v-btn icon class="mr-3" @click="toggleSearch = !toggleSearch">
          <v-icon>mdi-magnify</v-icon>
        </v-btn>
        <v-btn
          small
          :ripple="false"
          depressed
          class="text-capitalize"
          color="primary"
          @click="openDialog"
        >
          <v-icon class="mr-3">mdi-plus-circle</v-icon>
          {{ $t('actions.createForm') }}
        </v-btn>
      </v-col>
      <v-col v-if="toggleSearch" cols="12" class="pa-2 pt-0">
        <v-text-field
          v-model="search"
          :label="$t('actions.search')"
          hide-details
          width="100%"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col cols="12" class="pa-2 d-flex flex-column">
        <v-card height="100%" class="d-flex flex-column elevation-3 pa-2">
          <v-data-table
            :headers="headers"
            :items="data"
            :page.sync="page"
            :search="search"
            :custom-filter="formsFilter"
            :items-per-page="itemsPerPage"
            hide-default-footer
            @page-count="pageCount = $event"
          >
            <template v-slot:[`item.status`]="{ item }">
              {{ $t(`forms.statuses.${item.status}`) }}
            </template>

            <template v-slot:[`item.assignmentDate`]="{ item }">
              <p v-if="item.assignmentDate" class="text-center ma-0">{{ item.assignmentDate }}</p>
              <p v-else class="text-center ma-0">{{ 'NA' }}</p>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn small icon v-bind="attrs" v-on="on">
                    <v-icon small @click="getDetail(item.id)">mdi-eye</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('actions.show') }}</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-if="item.status === 'PENDING'" small icon v-bind="attrs" v-on="on">
                    <v-icon small @click="openConfirmDialog(item.id)">mdi-delete-circle-outline</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('actions.cancel') }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
          <v-spacer />
          <div class="d-flex flex-row align-center justify-end ml-auto pt-2">
            <v-autocomplete
              v-model="itemsPerPage"
              :label="$t('helpers.table.itemsPerPage')"
              :items="itemsPerPageOptions"
              item-text="text"
              item-value="value"
              outlined
              hide-details
              dense
              rounded
            />
            <v-pagination
              v-model="page"
              circle
              :length="pageCount"
            />
          </div>
        </v-card>
      </v-col>
    </v-row>

    <form-dialog
      v-model="object"
      :show-dialog="dialog"
      :isEditting="!isEditting"
      @toggle-dialog="state => dialog = state"
    />

    <v-dialog
      v-model="confirmDialog"
      width="40vw"
      persistent
    >
      <v-card>
        <v-card-title>
          {{ $t('helpers.cancelForm.confirm') }}
        </v-card-title>

        <v-card-actions>
          <v-btn
            small
            :ripple="false"
            outlined
            class="text-capitalize"
            dark
            color="primary"
            @click="closeConfirmDialog()"
          >
            {{ $t('actions.back') }}
          </v-btn>
          <v-spacer />
          <v-btn
            small
            :ripple="false"
            class="text-capitalize"
            dark
            color="primary"
            @click="cancel(deleteId)"
          >
            {{ $t('actions.continue') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { InformationCard } from 'Components/utils'
import { Dialog as FormDialog } from 'Components/forms'
import list from 'GraphQL/queries/requisitions/list.gql'
import cancelMutation from 'GraphQL/mutations/requisitions/cancel.gql'
import { filters, orm } from '@/mixins'

const dayjs = require('dayjs')
require('dayjs/locale/es')
var duration = require('dayjs/plugin/duration')
dayjs.locale('es')
dayjs.extend(duration)

export default {
  components: {
    InformationCard,
    FormDialog
  },

  mixins: [filters, orm],

  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      isEditting: false,
      object: {},
      dialog: false,
      search: '',
      toggleSearch: false,
      confirmDialog: false,
      deleteId: null,
      isConsulted: false,
      startAt: undefined,
      endAt: undefined
    }
  },
  computed: {
    ...mapState(['isDark', 'requisitions', 'users']),

    itemsPerPageOptions () {
      return [
        { text: '5', value: 5 },
        { text: '10', value: 10 },
        { text: this.$i18n.t('helpers.table.itemsPerPageAll'), value: -1 }
      ]
    },

    headers () {
      return [
        {
          text: this.$i18n.t('forms.caseNumber'),
          align: 'start',
          sortable: true,
          value: 'caseNumber'
        },
        { text: this.$i18n.t('forms.customer'), align: 'center', value: 'customer', sortable: true },
        { text: this.$i18n.t('forms.email'), align: 'center', value: 'email', sortable: true },
        { text: this.$i18n.t('forms.phone'), align: 'center', value: 'mainPhoneNumber', sortable: true },
        { text: this.$i18n.t('forms.date'), align: 'center', value: 'createdAt', sortable: true },
        { text: this.$i18n.t('forms.assignmentDate'), align: 'center', value: 'assignmentDate', sortable: true },
        { text: this.$i18n.t('forms.operator'), align: 'center', value: 'createdByName', sortable: true },
        { text: this.$i18n.t('forms.status'), align: 'center', value: 'status', sortable: true },
        { text: '', value: 'actions', sortable: false }
      ]
    },

    data () {
      return this.requisitions.list
    },

    validateSearch () {
      if (this.startAt && this.endAt) {
        return true
      }
      return false
    }
  },
  created () {
    this.object = Object.assign({}, this.$store.getters['requisitions/defaultObject'])
  },
  methods: {
    searchRequisitions (mode) {
      const action = 'history'
      let startAt
      let endAt
      if (mode === 'historic') {
        startAt = dayjs(this.startAt).unix()
        endAt = dayjs(this.endAt).unix()
        this.isConsulted = true
      } else {
        startAt = dayjs().add(-15, 'day').unix()
        endAt = dayjs().unix()
        this.isConsulted = false
      }
      this.$apollo.query({
        query: list,
        variables: {
          apiToken: this.users.entity.apiToken,
          startAt: startAt,
          endAt: endAt
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.requisitions
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },
    yesterdayRequisitonsCount () {
      const startDate = dayjs().add(-1, 'day').startOf('day').format('DD-MM-YYYY HH:mm:ss')
      const endDate = dayjs().startOf('day').format('DD-MM-YYYY HH:mm:ss')
      return this.requisitions.list.filter((item) => item.createdAt > startDate && item.createdAt < endDate).length
    },
    todayRequisitonsCount () {
      const startDate = dayjs().startOf('day').format('DD-MM-YYYY HH:mm:ss')
      const endDate = dayjs().add(1, 'day').startOf('day').format('DD-MM-YYYY HH:mm:ss')
      return this.requisitions.list.filter((item) => item.createdAt > startDate && item.createdAt < endDate).length
    },
    openDialog () {
      this.dialog = true
      this.isEditting = false
      this.object = Object.assign({}, this.$store.getters['requisitions/defaultObject'])
      this.object.startDateCreation = dayjs().unix()
    },
    evaluateResponse ({ status, errors, result, action }) {
      this.isLoading = false
      switch (status) {
        case 'OK':
          if (action === 'detail') {
            this.object = result[0]
            this.dialog = true
            this.isEditting = true
          } else if (action === 'history') {
            this.$store.commit('requisitions/setRequisitions', result)
          } else {
            console.log('Cancelado exitosamente')
            this.$store.commit('toggleSnackbar', {
              color: 'green darken-2',
              message: this.$t('helpers.cancel.success')
            })
            this.confirmDialog = false
          }
          break
        case 'UNPROCESSABLE':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'LIMITREACHED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.limit_reached')
          })
          break
      }
    },
    getDetail (id) {
      const action = 'detail'
      this.$apollo.query({
        query: list,
        variables: {
          apiToken: this.users.entity.apiToken,
          id: id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.requisitions
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    cancel (id) {
      const action = 'cancel'
      this.$apollo.mutate({
        mutation: cancelMutation,
        variables: {
          apiToken: this.users.entity.apiToken,
          id
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.cancelRequisition
        console.log('sadasd', action)
        this.evaluateResponse({ status, errors, result, action })
      }).catch(err => {
        console.log('Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    openConfirmDialog (id) {
      this.confirmDialog = true
      this.deleteId = id
    },

    closeConfirmDialog () {
      this.confirmDialog = false
      this.deleteId = null
    }
  }
}
</script>