<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="90vw"
    persistent
  >
    <v-card>
      <v-card-title class="d-flex align-start justify-space-between">
        <p>{{ title }}</p>
        <v-btn icon @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="better-scrollbar">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12" class="pa-2 py-0">
              <h4 class="font-weight-bold">{{ $t('forms.customer') }}</h4>
            </v-col>
            <v-col cols="12" md="4" class="pa-2">
              <!-- <v-text-field
                :label="$t('forms.caseNumber')"
                outlined
                dense
                rounded
                height="50px"
              /> -->
              <v-text-field
                v-model="object.caseNumber"
                :label="$t('forms.caseNumber')"
                :disabled="!isEditting"
                outlined
                dense
                rounded
                :error="hasErrors('caseNumber')"
                :error-messages="getErrors('caseNumber')"
              />
            </v-col>
            <v-col cols="12" md="4" class="pa-2">
              <v-text-field
                v-model="object.customer"
                :disabled="!isEditting"
                :label="$t('forms.customer')"
                outlined
                dense
                rounded
                :error="hasErrors('customer')"
                :error-messages="getErrors('customer')"
              />
            </v-col>
            <v-col cols="12" md="4" class="pa-2">
              <vue-tel-input-vuetify
                v-model="object.mainPhoneNumber"
                mode="international"
                :disabled="!isEditting"
                :label="$t('forms.phone')"
                class="phone-input"
                default-country="PE"
                outlined
                type="number"
                dense
                rounded
                :error="hasErrors('mainPhoneNumber')"
                :error-messages="getErrors('mainPhoneNumber')"
              />
            </v-col>
            <v-col cols="12" md="4" class="pa-2">
              <vue-tel-input-vuetify
                v-model="object.secondPhoneNumber"
                mode="international"
                :disabled="!isEditting"
                :label="$t('forms.optionalPhone')"
                class="phone-input"
                default-country="PE"
                outlined
                type="number"
                dense
                rounded
                :error="hasErrors('secondPhoneNumber')"
                :error-messages="getErrors('secondPhoneNumber')"
              />
            </v-col>
            <v-col cols="12" md="8" class="pa-2">
              <v-text-field
                v-model="object.email"
                :disabled="!isEditting"
                :label="$t('forms.email')"
                prepend-inner-icon="mdi-email"
                outlined
                dense
                rounded
                :error="hasErrors('email')"
                :error-messages="getErrors('email')"
              />
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <v-row no-gutters>
                <v-col cols="12">
                  <v-row no-gutters>
                    <v-col cols="12" class="pa-2 py-0">
                      <h4 class="font-weight-bold">{{ $t('forms.address') }}</h4>
                    </v-col>
                    <v-col cols="12" class="pa-2">
                      <v-text-field
                        id="locationTextField"
                        v-model="objectMap.location"
                        :disabled="!isEditting"
                        prepend-inner-icon="mdi-map-marker"
                        :label="$t('forms.location')"
                        outlined
                        dense
                        rounded
                        :error="hasErrors('address')"
                        :error-messages="getErrors('address')"
                      />
                      <input id="city2" type="hidden" name="city2">
                      <input id="cityLat" type="hidden" name="cityLat">
                      <input id="cityLng" type="hidden" name="cityLng">
                    </v-col>
                    <v-col cols="12" class="pa-2">
                      <v-text-field
                        v-model="object.guideMark"
                        :disabled="!isEditting"
                        :label="$t('forms.reference')"
                        outlined
                        dense
                        rounded
                        :error="hasErrors('guideMark')"
                        :error-messages="getErrors('guideMark')"
                      />
                    </v-col>
                    <v-col cols="12" class="pa-2">
                      <v-card height="230" color="green lighten-3">
                        <gm-map
                          v-model="objectMap"
                          :show="dialog"
                          :disabled="!isEditting"
                          :dark="isDark"
                          map-id="formMap"
                          class="green lighten-3"
                          width="100%"
                          height="100%"
                        />
                      </v-card>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="d-flex flex-column">
              <v-row no-gutters>
                <v-col cols="12" class="pa-2 py-0">
                  <h4 class="font-weight-bold">{{ $t('forms.vehicle') }}</h4>
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="object.plate"
                    :disabled="!isEditting"
                    :label="$t('forms.plate')"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('plate')"
                    :error-messages="getErrors('plate')"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="object.brand"
                    :disabled="!isEditting"
                    :label="$t('forms.mark')"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('brand')"
                    :error-messages="getErrors('brand')"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="object.model"
                    :disabled="!isEditting"
                    :label="$t('forms.model')"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('model')"
                    :error-messages="getErrors('model')"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="object.color"
                    :disabled="!isEditting"
                    :label="$t('forms.color')"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('color')"
                    :error-messages="getErrors('color')"
                  />
                </v-col>
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="object.year"
                    :disabled="!isEditting"
                    :label="$t('forms.year')"
                    outlined
                    type="number"
                    dense
                    rounded
                    :error="hasErrors('year')"
                    :error-messages="getErrors('year')"
                  />
                </v-col>
                <!-- <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    :label="$t('forms.deductible')"
                    outlined
                    dense
                    rounded
                  />
                </v-col> -->
                <v-col cols="12" md="6" class="pa-2">
                  <v-text-field
                    v-model="object.contactName"
                    :disabled="!isEditting"
                    :label="$t('forms.contactPerson')"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('contactName')"
                    :error-messages="getErrors('contactName')"
                  />
                </v-col>
                <v-col cols="12" class="pa-2">
                  <v-autocomplete
                    v-model="object.workshopId"
                    :disabled="!isEditting"
                    :label="$t('forms.referenceWorkshop')"
                    :items="referenceWorkshopOptions"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('workshopId')"
                    :error-messages="getErrors('workshopId')"
                  />
                </v-col>
                <v-col cols="12" class="pa-2">
                  <v-autocomplete
                    v-model="object.crashId"
                    :disabled="!isEditting"
                    :label="$t('forms.referenceCrash')"
                    :items="referenceCrashOptions"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('crashId')"
                    :error-messages="getErrors('crashId')"
                  />
                </v-col>
                <v-col cols="12" class="pa-2">
                  <v-text-field
                    v-model="object.comments"
                    :disabled="!isEditting"
                    :label="$t('forms.observations')"
                    outlined
                    dense
                    rounded
                    :error="hasErrors('comments')"
                    :error-messages="getErrors('comments')"
                  />
                </v-col>
              </v-row>
            </v-col>
            <v-col v-if="object.status && ['ASSIGNED', 'REFUSED', 'COMPLETED'].includes(object.status)" class="mt-4" cols="12">
              <v-row no-gutters>
                <v-col cols="12" class="pa-2 py-0">
                  <h3 class="font-weight-bold">{{ $t('forms.assignmentInformation') }}</h3>
                </v-col>
                <v-col cols="12" :sm="object.arrivalDate === null ? 4 : 3" class="pa-2 text-center">
                  <h4 class="font-weight-bold">{{ $t('forms.driver') }}</h4>
                  {{ object.driver }}
                </v-col>
                <v-col v-if="object.status === 'ASSIGNED'" cols="12" :sm="4" class="pa-2 text-center">
                  <h4 class="font-weight-bold">{{ $t('forms.locator') }}</h4>
                  <a :href="object.locator" target="_blank">{{ object.locator }}</a>
                </v-col>
                <v-col cols="12" :sm="object.arrivalDate === null ? 4 : 3" class="pa-2 text-center">
                  <h4 class="font-weight-bold">{{ $t('forms.estimatedArrivalTime') }}</h4>
                  {{ object.estimatedArrivalTime }}
                </v-col>
                <v-col v-if="object.arrivalDate !== null" cols="12" :sm="3" class="pa-2 text-center">
                  <h4 class="font-weight-bold">{{ $t('forms.arrivalDate') }}</h4>
                  {{ object.arrivalDate }}
                </v-col>
                <v-col v-if="object.status !== 'ASSIGNED'" cols="12" :sm="object.arrivalDate === null ? 4 : 3" class="pa-2 text-center">
                  <template v-if="object.confirmationDate">
                    <h4 class="font-weight-bold">{{ $t('forms.confirmationDate') }}</h4>
                    {{ object.confirmationDate }}
                  </template>
                  <template v-else-if="object.refusedDate">
                    <h4 class="font-weight-bold">{{ $t('forms.refusedDate') }}</h4>
                    {{ object.refusedDate }}
                  </template>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="isEditting" class="d-flex justify-space-between">
        <v-btn
          small
          :ripple="false"
          outlined
          class="text-capitalize"
          dark
          color="primary"
          @click="discardItem"
        >
          {{ $t('actions.discard') }}
        </v-btn>
        <v-btn
          small
          :ripple="false"
          depressed
          class="text-capitalize"
          color="primary"
          @click="saveItem(object.id)"
        >
          {{ $t('actions.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { orm } from '@/mixins'
import Maps from './Maps.vue'
import addMutation from '@/graphql/mutations/requisitions/add.gql'

export default {
  components: {
    'gm-map': Maps
  },

  mixins: [orm],

  model: {
    prop: 'data',
    event: 'set-object'
  },

  props: {
    showDialog: { type: Boolean, default: () => false },
    data: { type: Object, default: () => {} },
    isEditting: {
      type: Boolean,
      default: () => false
    }
  },

  data () {
    return {
      objectMap: {},
      errors: {},
      loaded: false,
      phoneMode: 'international'
    }
  },

  computed: {
    ...mapState(['isDark', 'users', 'workshops', 'crashes']),
    object: {
      get () {
        return this.data
      },

      set (value) {
        this.$emit('toggle-object', value)
      }
    },

    dialog: {
      get () {
        return this.showDialog
      },

      set (value) {
        this.$emit('toggle-dialog', value)
      }
    },

    mapDefaultObject () {
      return {
        location: '',
        coordinates: { lat: null, lng: null }
      }
    },

    title () {
      return this.$i18n.t('forms.new')
    },

    referenceWorkshopOptions () {
      console.log('workshops', this.workshops.list)
      return [
        ...this.workshops.list
      ]
    },

    referenceCrashOptions () {
      console.log('crashes', this.crashes.list)
      return [
        ...this.crashes.list
      ]
    }
  },

  watch: {
    dialog (value) {
      this.objectMap.location = this.object.address
      this.objectMap.coordinates = Object.assign({}, {
        lat: this.object.latitude,
        lng: this.object.longitude
      })
      // setTimeout(() => {
      //   if (this.phoneMode === 'international') {
      //     this.phoneMode = 'national'
      //   }
      // }, 0)
    }
  },

  created () {
    this.objectMap = Object.assign({}, this.mapDefaultObject)
  },

  mounted () {
  },

  methods: {
    async saveItem (id) {
      this.isLoading = true
      const data = {
        id: id,
        address: this.objectMap.location,
        brand: this.object.brand,
        email: this.object.email,
        caseNumber: this.object.caseNumber,
        color: this.object.color,
        comments: this.object.comments,
        contactName: this.object.contactName,
        coordinates: {
          latitude: this.objectMap.coordinates.lat,
          longitude: this.objectMap.coordinates.lng
        },
        customer: this.object.customer,
        guideMark: this.object.guideMark,
        mainPhoneNumber: this.object.mainPhoneNumber,
        model: this.object.model,
        plate: this.object.plate,
        secondPhoneNumber: this.object.secondPhoneNumber,
        startDateCreation: this.object.startDateCreation,
        workshopId: this.object.workshopId,
        crashId: this.object.crashId,
        year: this.object.year
      }
      console.log(this.object, data)
      if (data.id) {
        await this.edit(data)
      } else {
        await this.add(data)
      }
    },

    evaluateResponse ({ status, errors }) {
      this.isLoading = false
      switch (status) {
        case 'OK':
          this.resetErrors()
          this.discardItem()
          this.$store.commit('toggleSnackbar', {
            color: 'green darken-2',
            message: this.$t('helpers.save.success')
          })
          this.$store.dispatch('requisitions/getList', true)
          break
        case 'UNPROCESSABLE':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'BADREQUEST':
          this.errors = this.parseErrors(errors)
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.invalidFields')
          })
          break
        case 'LIMITREACHED':
          this.$store.commit('toggleSnackbar', {
            color: 'orange darken-2',
            message: this.$t('errors.limit_reached')
          })
          break
      }
    },

    add (data) {
      console.log('entro?', data, this.users.entity.apiToken)
      this.$apollo.mutate({
        mutation: addMutation,
        variables: {
          apiToken: this.users.entity.apiToken,
          data
        },
        fetchPolicy: 'no-cache'
      }).then(response => {
        const { status, errors, result } = response.data.addRequisition
        this.evaluateResponse({ status, errors, result })
      }).catch(err => {
        console.log('[Devices Dialog] Error handled', err)
        this.$store.commit('toggleSnackbar', {
          color: 'red darken-2',
          message: this.$t('helpers.errors.disaster')
        })
      })
    },

    discardItem () {
      this.dialog = false
      this.resetErrors()
      this.object = Object.assign({}, this.$store.getters['requisitions/defaultObject'])
      // this.objectMap = Object.assign({}, this.mapDefaultObject)
    }
  }
}
</script>
