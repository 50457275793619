<template>
  <v-app :dark="isDark">
    <v-content>
      <gm-app-bar />

      <div class="px-2 py-0">
        <transition name="component-fade" mode="out-in">
          <router-view />
        </transition>
      </div>
    </v-content>

    <!-- <gm-footer is-full /> -->
  </v-app>
</template>

<script>
import { Header } from 'Components/layout'
import { mapState } from 'vuex'
import mqttjs from 'mqtt'

const dayjs = require('dayjs')
require('dayjs/locale/es')
dayjs.locale('es')

export default {
  components: {
    'gm-app-bar': Header
    // 'gm-footer': Footer
  },

  data () {
    return {
      mqtt: null,
      mqttToken: 'uO9WdLR1lPoyvc0mO1Qs8t8BI16TMop50oR4YyV9b8wwamGMdk4bE1AaQwTtI8oz'
    }
  },

  computed: {
    ...mapState(['isDark'])
  },

  watch: {
    isDark (newVal, oldVal) {
      this.$vuetify.theme.dark = newVal
    }
  },

  mounted () {
    if (!this.mqtt) {
      this.initMqtt()
    }
  },

  beforeDestroy () {
    if (this.mqtt) {
      console.log('[MQTT] Destroying session')
      this.mqtt.end(true)
      this.mqtt = null
      // console.log('hola')
    }
  },

  methods: {
    initMqtt () {
      console.log('[MQTT] Initializing')
      this.mqtt = mqttjs.connect('mqtts://mqtt.flespi.io', {
        username: `FlespiToken ${this.mqttToken}`
      })

      this.mqtt.on('connect', (err) => {
        if (!err) {
          console.log('[MQTT] Error connecting with Flespi')
        } else {
          console.log('[MQTT] Connected')

          let topic = 'solsatel/logistic/requisition'

          if (process.env.NODE_ENV === 'development') {
            topic += '_dev'
          }

          this.mqtt.subscribe(topic, (err) => {
            if (!err) {
              console.log('[MQTT] Subscribed successfully to', topic)
            } else {
              console.log('[MQTT] Error subscribint to topic', topic, err)
            }
          })
        }
      })

      this.mqtt.on('message', (topic, message) => {
        this.handlerIncommingMessage(topic, message)
      })
    },

    handlerIncommingMessage (topic, message) {
      message = JSON.parse(message)
      console.log(message)
      this.$store.commit('requisitions/updateItem', {
        id: message.id.toString(),
        caseNumber: message.caseNumber,
        customer: message.customer,
        mainPhoneNumber: message.mainPhoneNumber,
        secondPhoneNumber: message.secondPhoneNumber,
        email: message.email,
        address: message.address,
        guideMark: message.guideMark,
        latitude: message.latitude,
        longitude: message.longitude,
        driver: message.driver,
        locator: message.locator,
        plate: message.plate,
        brand: message.brand,
        model: message.model,
        color: message.color,
        createdBy: {
          name: message.createdBy
        },
        year: message.year,
        contactName: message.contactName,
        comments: message.comments,
        workshopId: message.workshopId,
        status: message.status,
        assignmentDate: message.assignmentDate === null ? message.assignmentDate : dayjs(parseInt(message.assignmentDate)).format('DD-MM-YYYY HH:mm:ss'),
        arrivalDate: message.arrivalDate === null ? message.arrivalDate : dayjs(parseInt(message.arrivalDate)).format('DD-MM-YYYY HH:mm:ss'),
        confirmationDate: message.confirmationDate === null ? message.confirmationDate : dayjs(parseInt(message.confirmationDate)).format('DD-MM-YYYY HH:mm:ss'),
        estimatedArrivalTime: message.estimatedArrivalTime === null ? message.estimatedArrivalTime : dayjs(parseInt(message.estimatedArrivalTime)).format('HH:mm'),
        refusedDate: message.refusedDate === null ? message.refusedDate : dayjs(parseInt(message.refusedDate)).format('DD-MM-YYYY HH:mm:ss'),
        createdAt: message.createdAt === null ? message.createdAt : dayjs(parseInt(message.createdAt)).format('DD-MM-YYYY HH:mm:ss')
      })
    }
  }
}
</script>

<style lang="scss">
</style>