const assets = {}

assets.install = (Vue) => {
  Vue.prototype.$assets = {
    favicon: require('@/assets/favicon.svg'),
    logo: require('@/assets/logo.svg'),
    logoWhite: require('@/assets/logo.svg'),
    robot: require('@/assets/robot.png'),
    faviconWhite: require('@/assets/favicon.svg')
  }
}

export default assets