<template>
  <v-card height="100%" :class="isDark ? '' : 'grey lighten-4'" class="d-flex align-center justify-space-between elevation-3 pa-2">
    <div>
      <v-icon>{{ icon }}</v-icon>
    </div>
    <div>
      <span style="letter-spacing: 0.0892857143em;" class="font-weight-light">{{ time }}</span>
    </div>
    <div>
      <v-avatar
        size="20"
        :color="badgeColor"
      />
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    time: {
      type: String,
      default: ''
    },
    badgeColor: {
      type: String,
      default: 'primary'
    },
    icon: {
      type: String,
      default: 'mdi-clock'
    },
    isDark: {
      type: Boolean,
      default: false
    }
  }
}
</script>
