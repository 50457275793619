<template>
  <div>
    <v-app-bar color="transparent" class="elevation-4" :height="72.5">
      <v-app-bar-nav-icon v-if="isMobile" @click="$store.commit('setDrawer', true)" />

      <v-toolbar-title class="d-flex align-center">
        <img :src="logo" :height="hasWhitelabel ? 40 : 50" :class="{'mr-3': hasWhitelabel}">
      <!-- {{ appName }} -->
      </v-toolbar-title>

      <v-spacer />
      <template v-if="!isMobile">
        <v-btn
          v-for="(el, i) in sidebarLinks"
          :key="i"
          class="text-capitalize mx-2 btn--header"
          color="primary"
          :text="$route.path !== el.path"
          depressed
          :ripple="false"
          :to="el.path"
        >
          {{ $t(`pages.${el.name}`) }}
        </v-btn>
      </template>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-avatar
            v-if="hasAvatar"
            class="elevation-0"
            size="48"
            style="cursor: pointer;"
            v-on="on"
          >
            <v-img :src="avatar + `?random=${randomNumber}`" contain />
          </v-avatar>

          <v-avatar
            v-else
            class="elevation-0 font-weight-bold"
            :color="`${isDark ? 'secondary' : 'primary'} white--text`"
            size="48"
            style="cursor: pointer;"
            v-on="on"
          >
            {{ avatar }}
          </v-avatar>
        </template>

        <v-list dense>
          <!-- <v-list-item to="/Profile">{{ $t('headers.profile') }}</v-list-item> -->
          <v-list-item @click="$store.dispatch('users/logout')">{{ $t('users.signOut') }}</v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-navigation-drawer
      v-model="navigationDrawer"
      fixed
      temporary
    >
      <v-expansion-panels tile flat>
        <v-expansion-panel class="pa-0">
          <v-expansion-panel-header>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar
                  v-if="hasAvatar"
                  class="elevation-0"
                  size="48"
                  style="cursor: pointer;"
                >
                  <v-img :src="avatar + `?random=${randomNumber}`" contain />
                </v-avatar>

                <v-avatar
                  v-else
                  class="elevation-0 font-weight-bold"
                  :color="`${isDark ? 'secondary' : 'primary'} white--text`"
                  size="48"
                  style="cursor: pointer;"
                >
                  {{ avatar }}
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-title>{{ fullName }}</v-list-item-title>
            </v-list-item>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list dense>
              <!-- <v-list-item to="/Profile">{{ $t('headers.profile') }}</v-list-item> -->
              <v-list-item @click="$store.dispatch('users/logout')">{{ $t('users.signOut') }}</v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <v-divider />
      <v-list flat dense>
        <v-list-item-group>
          <v-list-item v-for="(el, i) in sidebarLinks" :key="i" active-class="primary white--text" :to="el.path">
            <v-list-item-title>
              <span>
                {{ $t(`pages.${el.name}`) }}
              </span>
            </v-list-item-title>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex'

export default {
  data () {
    return {
      loginAs: false,
      newAccount: '',
      randomNumber: 0
    }
  },

  computed: {
    ...mapState(['users', 'isDark', 'whitelabel', 'drawer']),
    ...mapGetters(['isMobile', 'version']),

    ...mapGetters({
      avatar: 'users/avatar',
      hasAvatar: 'users/hasAvatar',
      fullName: 'users/fullName'
    }),

    ...mapGetters(['isMobile']),

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    },

    logo () {
      if (this.whitelabel.logo === undefined) {
        return this.isDark ? this.$assets.faviconWhite : this.$assets.favicon
      }

      return this.whitelabel.logo
    },

    appName () {
      if (this.whitelabel.name === undefined) {
        return this.$i18n.t('app.site')
      }

      return this.whitelabel.name
    },

    user () {
      return this.users.entity
    },

    sidebarLinks () {
      if (this.user.kind === 'ADMIN') {
        return [
          {
            path: '/Home',
            name: 'home'
          },
          {
            path: '/Users',
            name: 'users'
          },
          {
            path: '/Workshops',
            name: 'workshops'
          },
          {
            path: '/Crashes',
            name: 'crashes'
          },
          {
            path: '/Reports',
            name: 'reports'
          }
        ]
      } else if (this.user.kind === 'SUPERVISOR') {
        return [
          {
            path: '/Reports',
            name: 'reports'
          }
        ]
      } else {
        return [
          {
            path: '/Operator/Home',
            name: 'operator-home'
          }
        ]
      }
    },

    navigationDrawer: {
      get () {
        return this.drawer
      },

      set (value) {
        this.$store.commit('setDrawer', value)
      }
    }
  },

  watch: {
    user (newVal, oldVal) {
      this.randomNumber = parseInt(Math.random() * 1000000)
    }
  },

  methods: {
    ...mapMutations(['toggleDark'])
  }
}
</script>