import { Loader } from '@googlemaps/loader'

const googlemaps = {}

googlemaps.install = (Vue) => {
  Vue.prototype.$googlemaps = new Loader({
    apiKey: 'AIzaSyBJ-kgVjzpUMW8KJsuqcdM1ofu_vRrpzps',
    version: '3',
    libraries: ['drawing', 'places'],
    language: 'en'
  })
}

export default googlemaps