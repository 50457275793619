<template>
  <v-footer :app="isFull" :color="`${color} ${fontColor}--text`" class="d-flex justify-center">
    <template v-if="hasWhitelabel">
      &copy; {{ whitelabel.companyName }}
    </template>

    <template v-else>
      {{ $t('copyright.helpers.madeWith') }}
      &hearts;
      {{ $t('copyright.helpers.by') }}
      {{ $t('copyright.developer.name') }}
      | {{ $t('copyright.powered.by') }}
    </template>
  </v-footer>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: {
    isFull: {
      type: Boolean,
      default: () => true
    },

    color: {
      type: String,
      default: () => 'secondary'
    },

    fontColor: {
      type: String,
      default: () => 'white'
    }
  },

  computed: {
    ...mapGetters(['version']),
    ...mapState(['whitelabel']),

    hasWhitelabel () {
      if (this.whitelabel.logo === undefined) {
        return false
      }

      return true
    }
  }
}
</script>