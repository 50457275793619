<template>
  <v-card
    height="100%"
    class="d-flex align-center elevation-3"
    style="overflow: hidden;"
  >
    <v-container fluid class="pa-0">
      <v-row no-gutters>
        <v-col :class="color" cols="4" class="pa-2 d-flex align-center justify-center">
          <v-icon v-if="reverse" :color="textColor" size="56">{{ icon }}</v-icon>
          <h1 v-else style="font-size: 2.5em;" :class="isDark ? 'white--text' : 'grey--text text--darken-3'">{{ value }}</h1>
        </v-col>
        <v-col :class="color" :cols="reverse ? 4 : 8" class="pa-2 d-flex flex-column justify-center">
          <span :class="`${textColor}--text`" class="font-weight-bold">{{ title }}</span>
          <span :class="`${textColor}--text`" class="font-weight-light text-truncate">{{ subtitle }}</span>
        </v-col>
        <v-col v-if="reverse" cols="4" :class="isDark ? 'grey darken-3' : 'grey lighten-3'" class="pa-2 d-flex align-center justify-center">
          <h1 style="font-size: 2.5em;" :class="isDark ? 'white--text' : 'grey--text text--darken-3'">{{ value }}</h1>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    isDark: {
      type: Boolean,
      default: false
    },
    reverse: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    }
  }
}
</script>
